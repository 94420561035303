import '../static/styles/project-card.css'

const ProjectCard = ({ projectData, handleClick }) => {
  const { title, year, img } = projectData || {};

  return (
    <div style={{ cursor: "pointer" }} onClick={handleClick}>
      <span className="portfolio-item d-block">
        <div className="foto">
          <div>
            <img
              src={img[0]}
              alt="projectImages"
              height="230"
              style={{ marginBottom: 0, paddingBottom: 0, position: 'relative' }}
            />
            <span className="project-date">{year}</span>
            <br />
            <p className="project-title-settings mt-3">
              {title}
            </p>
          </div>
        </div>
      </span>
    </div>
  );
}

export default ProjectCard;