import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Header from './components/Header';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Experience from './components/Experience';

function App() {
  const data = require('./static/data.json');
  const { projectData, skills, experiences } = data;

  return (
    <>
      <Header />
      <Projects projectData={projectData} />
      <Skills skills={skills} />
      <Experience experiences={experiences} />
      <div style={{ height: 400 }}></div>
    </>

  );
}

export default App;
