import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import '../static/styles/experience.css';
import "react-vertical-timeline-component/style.min.css";
import React from 'react';
import { Avatar, Card } from '@mui/material';
import CardHeader from "@mui/material/CardHeader";


const Experience = React.memo(({experiences}) => {
    return (
        <div className='experienceContainer'>
            <h2 className='projectTitle' style={{textAlign: 'center'}}>Experience & Education</h2>
            <VerticalTimeline>
                {
                    experiences.map((experience, i) => (
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            date={experience.years}
                            icon={
                                <div className='experience-icon-container'>
                                    <i class={`${experience.mainSkillIcon} experience-icon`}></i>
                                </div>
                            }
                            iconStyle={{ background: '#A69CAC', color: '#fff' }}
                            key={i}
                        >
                            <Card variant="outlined">
                                <CardHeader 
                                    title={experience.title}
                                    titleTypographyProps={{
                                        className: "vertical-timeline-element-title experienceCardHeader",
                                        component: 'h3'
                                    }}
                                    subheaderTypographyProps={{
                                        className: "vertical-timeline-element-subtitle experienceCardHeader",
                                        component: 'h4'
                                    }}
                                    subheader={experience.company}
                                    avatar={<Avatar variant="square" alt={`${experience.company} logo`} src={`/images/${experience.companyLogo}`} />}
                                />
                            </Card>
                        </VerticalTimelineElement>
                    ))
                }
                
            </VerticalTimeline>
        </div>
    );
});
 
export default Experience;