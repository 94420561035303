import '../static/styles/projects.css'
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import ProjectCard from './ProjectCard';
import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProjectDetailsModal from './ProjectDetailsModal';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  pl: 8,
  pr: 8,
  pt: 8,
  outline: 0,
};

const Projects = ({ projectData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleOpen = (index) => {
    setModalData(projectData[index]);
    setIsOpen(true);
  }
  const handleClose = () => setIsOpen(false);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  return (
    <div className={'projectsContainer'}>
      <h2 className={'projectTitle'}>Projects</h2>
      <Carousel
        showDots={true}
        responsive={responsive}
        keyBoardControl={true}
        infinite
        transitionDuration={500}
        containerClass={"carousel-container carousel-container-custom"}
        itemClass="cardContainer"
      >
        {projectData.map((data, index) => {
          return (
            <ProjectCard projectData={data} handleClick={() => handleOpen(index)} key={index} />
          );
        })}
      </Carousel>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <ProjectDetailsModal modalData={modalData} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default Projects;