import React, { useState } from "react";
import AwesomeSlider from 'react-awesome-slider';
import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import { Icon } from '@iconify/react';
import Popover from '@mui/material/Popover';

const ProjectDetailsModal = ({ modalData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const { title, img, description, url, infoText, skills } = modalData;

  const images = img ? img.map((elem, i) => {
    return <div key={i} data-src={elem} />;
  }) : <></>;

  const skillsSection = skills ? skills.map((elem, i) => {
    return <i className={`${elem} skill-icon`} key={i} />
  }) : <></>;

  return (
    <>
      <div style={{ paddingLeft: '8px', paddingRight: '8px', paddingBottom: '8px', backgroundColor: '#F1DAC4' }}>
        <div style={{ fontSize: '0.75rem', paddingTop: '4px', paddingBottom: '2px' }}>
          <Icon icon="twemoji:red-circle" />
          <Icon icon="twemoji:yellow-circle" style={{ marginLeft: '5px' }} />
          <Icon icon="twemoji:green-circle" style={{ marginLeft: '5px' }} />
        </div>
        {
          images && (
            <AwesomeSlider animation="foldOutAnimation" bullets={false} cssModule={[CoreStyles, AnimationStyles]}>
              {images}
            </AwesomeSlider>
          )
        }
      </div>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <h3 className="projectDetailTitle">{title}</h3>
        {
          url && (
            <a href={url} target="_blank" rel="noopener noreferrer" style={{ display: 'block', color: '#161B33' }}>
              <Icon icon="fa-solid:external-link-alt" style={{ marginLeft: "10px" }} />
            </a>
          )
        }
        {
          infoText && (
            <>
              <Icon
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                icon="bi:info-circle"
                style={{ marginLeft: "10px", display: 'block', color: '#161B33', fontSize: "12px" }}
              />
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <div style={{ fontSize: "12px", padding: "4px" }}>
                  {infoText}
                </div>
              </Popover>
            </>
          )
        }
      </div>
      {
        description && (
          <p className="modal-description">
            {description}
          </p>
        )
      }
      {
        skills && (
          <p style={{ fontSize: '300%', textAlign: 'center' }}>
            {skillsSection}
          </p>
        )
      }
    </>
  );
}

export default ProjectDetailsModal;
