import '../static/styles/skills.css';
import React from 'react';

const Skills = React.memo(({skills}) => {
    return (
        <div className='skillsContainer'>
            <h2 className={'skillsTitle'}>Skills</h2>
            <ul className='skills-ul'>
                {
                    skills.map((skill, i) => (
                        <li className='skills-li' key={i}>
                            <i className={`${skill.class} skills-icon`} style={{ fontSize: "400%" }}>
                                <p className='skills-label' style={{ fontSize: "30%", marginTop: "4px" }}>
                                    {skill.label}
                                </p>
                            </i>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
});
 
export default Skills;